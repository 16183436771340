const mappingType = type => {
  switch (type) {
    case "alternative":
      return "Alternativas";
    case "book":
      return "Cursos";
    case "cast_album":
      return "Álbums";
    case "cast_track":
      return "Faixas";
    case "courses_class":
      return "Aulas de cursos";
    case "interactive_course_chapter":
      return "Capítulos de cursos interativos";
    case "interactive_course":
      return "Cursos interativos";
    case "monitoring":
      return "Aulas de monitorias";
    case "question":
      return "Questões";
    case "strategic_trail_class":
      return "Aulas de trilhas estratégicas";
    case "strategic_trail":
      return "Trilhas estratégicas";
  }

  return "N/A";
};

export default mappingType;
